<template>
    <b-container>
        <b-row style="
        display: flex;
        flex-wrap: wrap;
        margin-right: -9px;
        margin-left: -12px;"
        >
            <b-col lg="12" sm="12">
                <div v-if="farmerDetails.length === 0">
                    <p class="d-flex justify-content-center">No Data</p>
                </div>
                <b-row v-for="(farmer, index) in farmerDetails" :key="index">
                    <span class="text-dark ml-4" style="font-weight: bold;">ID#{{ farmer.id }}</span>
                    <SingleFarmerDetails :farmer="farmer" :loading="loading"/>
                    <span style="border-bottom: 3px solid #8b8b8b;"></span>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { farList } from '../../api/routes'
import SingleFarmerDetails from './SingleFarmerDetails'

export default {
    name: 'FarmerDetailsList',
    props: ['appId'],
    components: {
        SingleFarmerDetails
    },
    data () {
        return {
            loading: false
        }
    },
    computed: {
        farmerDetails () {
            return this.$store.state.IrriPumpInoformation.landFarmerList
        }
    },
    methods: {
        getData () {
            this.loading = true
            RestApi.getData(irriSchemeServiceBaseUrl, farList + '/' + this.appId ?? 0, { application_type: 2 })
                .then((response) => {
                    if (response.success) {
                        this.$store.dispatch('IrriPumpInoformation/addLandFarmerList', response.data)
                    }
                    this.loading = false
                })
                .catch(() => {
                    this.loading = true
                })
        }
    },
    created () {
        this.getData()
    }
}
</script>
