<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
              <b-form  @submit.prevent="handleSubmit(register)" >
                <b-row>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="organization"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('org_pro.organization') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="pumpInformation.org_id"
                          :options="organizationList"
                          id="org_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Project" vid="project_id">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="project"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('irriPumpInformation.project') }}
                        </template>
                        <b-form-select
                          plain
                          v-model="pumpInformation.project_id"
                          :options="projectList"
                          id="project_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="division"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('org_pro_division.division') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="pumpInformation.division_id"
                          :options="divisionList"
                          id="division_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="district"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('org_pro_district.district') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="pumpInformation.district_id"
                          :options="districtList"
                          id="district_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Upazila" vid="upazilla_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="upazila"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('org_pro_upazilla.upazilla') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="pumpInformation.upazilla_id"
                          :options="upazilaList"
                          id="upazila_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Union" vid="union_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="union_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('org_pro_union.union') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="pumpInformation.union_id"
                          :options="unionList"
                          id="union_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                      <ValidationProvider name="Mouza" vid="mouza_id" rules="">
                          <b-form-group
                              class="row"
                              label-cols-sm="3"
                              label-for="mouza_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('irrigation_config.mouza') }}
                              </template>
                              <b-form-select
                              plain
                              v-model="pumpInformation.mouza_id"
                              :options="mouzaList"
                              id="mouza_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Pump Id" vid='pump_id' rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="pump_id"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('irriPumpInformation.pump_id') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="pump_id"
                          v-model="pumpInformation.pump_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Total Farmer" vid="total_farmer" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="3"
                              label-for="total_farmer"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                          >
                              <template v-slot:label>
                                  {{ $t('externalUserIrrigation.total_farmer') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="total_farmer"
                                  v-model="pumpInformation.total_farmer"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <!-- <b-col lg="6" sm="12">
                    <ValidationProvider name="Mouza Name" vid='mouza_no'>
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="mouza_no"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('irriPumpInformation.mouza_no') }}
                        </template>
                        <b-form-input
                          id="mouza_no"
                          v-model="pumpInformation.mouza_no"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col> -->
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="JL No" vid="jl_no">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="jl_no"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('irriPumpInformation.jl_no') }}
                        </template>
                        <b-form-input
                          id="jl_no"
                          v-model="pumpInformation.jl_no"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Plot No" vid="plot_no">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="plot_no"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('irriPumpInformation.plot_no') }}
                        </template>
                        <b-form-input
                          id="plot_no"
                          v-model="pumpInformation.plot_no"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Water User Group" vid="water_group_id">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="water_group_id"
                        slot-scope="{ valid, errors }"
                        style="font-size: 13px"
                      >
                      <template v-slot:label>
                        {{ $t('irriPumpInformation.water_group_id') }}
                        </template>
                        <b-form-input
                          id="water_group_id"
                          v-model="pumpInformation.water_group_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Latitude" vid="latitude">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="latitude"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('irriPumpInformation.latitude') }}
                        </template>
                        <b-form-input
                          id="latitude"
                          v-model="pumpInformation.latitude"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Longitude" vid="longitude">
                      <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="longitude"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('irriPumpInformation.longitude') }}
                        </template>
                        <b-form-input
                          id="longitude"
                          v-model="pumpInformation.longitude"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { pumpInformationStore, pumpInformationUpdate } from '../../api/routes'
import { mapGetters } from 'vuex'

export default {
  name: 'FormLayout',
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      pumpInformation: {
        org_id: '0',
        pump_id: '',
        project_id: '0',
        division_id: '0',
        district_id: '0',
        upazilla_id: '0',
        union_id: '0',
        mouza_id: '0',
        // mouza_no: '',
        jl_no: '',
        total_farmer: '',
        plot_no: '',
        water_group_id: '',
        latitude: '',
        longitude: ''
      },
      districtList: [],
      upazilaList: [],
      unionList: [],
      mouzaList: [],
      projectList: []
    }
  },
  created () {
    // if (this.$store.state.Auth.activeRoleId.id !== 1) {
    //   const officeId = this.$store.state.Auth.authUser.office_id
    //   const officeList = this.$store.state.commonObj.officeList
    //   const officeData = officeList.find(office => office.value === officeId)
    //   this.pumpInformation.division_id = officeData.division_id
    //   this.pumpInformation.district_id = officeData.district_id
    //   this.pumpInformation.upazilla_id = officeData.upazilla_id
    //   this.pumpInformation.union_id = officeData.union_id
    //   this.pumpInformation.org_id = this.$store.state.Auth.authUser.org_id
    // }
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.pumpInformation.org_id = this.authUser.org_id
    } else if (this.authUser.role_id === 0) {
      this.pumpInformation.org_id = this.authUser.office_detail.org_id
      this.pumpInformation.division_id = this.authUser.office_detail.division_id
      this.pumpInformation.district_id = this.authUser.office_detail.district_id
      this.pumpInformation.upazilla_id = this.authUser.office_detail.upazilla_id
      this.pumpInformation.union_id = this.authUser.office_detail.union_id
    }
    if (this.id) {
      const tmp = this.getpumpInformationData()
      // Object.freeze(tmp)
      this.pumpInformation = tmp
    }
  },
  mounted () {
    core.index()
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    }
  },
  watch: {
    'pumpInformation.org_id': function (newVal, oldVal) {
      this.getProjectlist(newVal)
    },
    'pumpInformation.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'pumpInformation.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'pumpInformation.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    },
    'pumpInformation.union_id': function (newVal, oldVal) {
      this.mouzaList = this.getMouzaList(newVal)
    }
  },
  methods: {
    getpumpInformationData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.pumpInformation.id) {
        result = await RestApi.putData(irriSchemeServiceBaseUrl, `${pumpInformationUpdate}/${this.id}`, this.pumpInformation)
      } else {
        result = await RestApi.postData(irriSchemeServiceBaseUrl, pumpInformationStore, this.pumpInformation)
      }

      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('IrriConfig/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getProjectlist (orgId) {
      this.projectList = this.$store.state.IrriConfig.commonObj.projectList.filter(project => project.org_id === orgId && project.status === 0)
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)

      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }

      return unionList
    },
    getMouzaList (unionId = null) {
      const mouzaList = this.$store.state.IrriConfig.commonObj.mouzaList.filter(item => item.status === 0)
      if (unionId) {
        return mouzaList.filter(union => union.union_id === unionId)
      }
      return mouzaList
    }
  }
}
</script>
